import { DOSSIER_COMMON_INPUTS, actions } from './dossier'
import { RENTAL_MANAGEMENT_TYPES } from '@/forms/selectOptions'

function validRentEndDate ({ getFormValues }) {
  const values = getFormValues()
  if (!values.rental_end_date) return true

  const startDate = new Date(values.rental_start_date)
  const endDate = new Date(values.rental_end_date)

  // To compare only dates and prevent side-effects due to hh:mm:ss:ms
  startDate.setHours(0, 0, 0, 0)
  endDate.setHours(0, 0, 0, 0)
  return startDate.getTime() <= endDate.getTime()
}

const schema = [
  ...DOSSIER_COMMON_INPUTS,
  {
    component: 'div',
    class: 'tw-max-w-xl',
    children: [
      {
        component: 'h2',
        children: 'Verhuurovereenkomst'
      },
      {
        type: 'select',
        name: 'rental_management_type',
        label: 'Type beheer',
        placeholder: 'Selecteer beheertype',
        options: RENTAL_MANAGEMENT_TYPES
      },
      {
        type: 'date',
        name: 'agreement_signature_date',
        label: 'Datum ondertekening',
        placeholder: 'YYYY-MM-DD',
        validation: 'bail|optional|date:YYYY-MM-DD',
        outerClass: 'tw-m-0'
      },
      {
        type: 'select',
        name: 'rental_term',
        label: 'Duurtijd huurcontract',
        placeholder: 'Duurtijd huurcontract',
        options: [] // Options filled async
      }
    ]
  },
  {
    component: 'div',
    class: 'tw-my-4 tw-gap-x-4 tw-max-w-xl tw-grid tw-grid-cols-2',
    children: [
      {
        type: 'date',
        name: 'rental_start_date',
        label: 'Huur startdatum',
        placeholder: 'YYYY-MM-DD',
        validation: 'bail|optional|date:YYYY-MM-DD',
        outerClass: 'tw-m-0'
      },
      {
        type: 'date',
        name: 'rental_end_date',
        label: 'Huur einddatum',
        placeholder: 'YYYY-MM-DD',
        validation: 'bail|optional|date:YYYY-MM-DD|validRentEndDate',
        validationRules: { validRentEndDate },
        validationMessages: {
          validRentEndDate:
            'Einddatum kan niet voor de startdatum vallen.'
        },
        outerClass: 'tw-m-0'
      }
    ]
  }
]

export default {
  heading: 'Dossier',
  schema,
  actions
}
